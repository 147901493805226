html, body
{
	margin           : 0;
	padding          : 0;
	border           : 0px;
	// height           : 100%;
	color            : #000;
	background-color : #fff;
	font-size        : 14px;
	font-family      : YuGothic, 'Yu Gothic', sans-serif;
}

html,
body,
a,
p,
div,
h1,
h2,
h3,
h4,
h5,
h6
{
	text-transform : none;
	font-family    : YuGothic, 'Yu Gothic', sans-serif;
}

a
{
	color           : #2e9cd0;
	text-decoration : none;
}

.clear
{
	clear : both;
}

.container
{
	margin-bottom : 3%;
}

.pocket
{
	width      : 100%;
	max-width  : 280px;
	margin-top : 3%;
}

.info
{
	width        : 100%;
	max-width    : 260px;
	margin-top   : 2%;
	margin-left  : auto;
	margin-right : auto;
	text-align   : center;

	table
	{
		width        : 100%;
		padding-left : 10%;
	}
}

.companyname
{
	font-size     : 2.0em;
	font-weight   : bold;
	margin-top    : 8%;
	margin-bottom : 8%;
	font-family   : "a-otf-midashi-go-mb31-pr6n";
}

.info_title
{
	width          : 30%;
	text-align     : left;
	padding-top    : 2%;
	font-size      : 0.9em;
	vertical-align : top;
}

.info_content
{
	width          : 60%;
	text-align     : left;
	padding-top    : 2%;
	font-size      : 0.9em;
	vertical-align : top;
}

@media screen and ( max-width:768px ) {
	.pocket
	{
		padding-top : 6%;
		max-width   : 235px;
	}
	.container
	{
		margin-bottom : 6%;
	}
	.info_title
	{
		font-size : 1.0em;
	}
	.info_content
	{
		font-size : 1.0em;
	}
}